import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

    // <h2>Common topics include:</h2>
    // <div className="listCol">
    //     <ul>
    //         <strong>Net-Zero Efforts</strong>
    //         <li>Sustainability policy</li>
    //         <li>Energy efficiency </li>
    //         <li>Electrification</li>
    //     </ul>
    //     <ul>
    //         <strong>Resilience</strong>
    //         <li>Success planning</li>
    //         <li>Energy storage</li>
    //         <li>Microgrids</li>
    //         <li>DER strategy</li>
    //     </ul>
    //     <ul>
    //         <strong>Success Planning</strong>
    //         <li>Resource coordination</li>
    //         <li>Decision-making</li>
    //         <li>Technology choices</li>
    //         <li>Asset portfolio optimization</li>
    //         <li>Project and risk management</li>
    //         <li>Transaction support</li>
    //     </ul>
    //     <ul></ul>
    // </div>
const CustomAdvisory = () => {
    return (
        <Layout pageTitle='Custom Advisory Services' nextBottomSlideBtn="What are Your Energy Priorities?" nextBottomSlideBtnLink="/EnergyPriorities" id="advisory">
            <div className="fullWidth" id="customAdvisory">
                <div>
                    <br></br>
                    <div className="pageContent">
                        <div className="box" style={{ animation: `fadeIn .75s ease-in-out forwards`, opacity: 0 }}>
                            <h2>Southern 360 makes energy simple.</h2>
                            <p>Our advisors support your organization's sustainability and resilience projects from investment and design through implementation and optimization. Our advisors are multi-disciplinary specialists, bringing innovative energy offerings, technical expertise, award-winning services, and flexible buying options under one roof.</p>
                        </div>
                        <div className="box" style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}>
                            <StaticImage
                                alt=""
                                src="../images/customAdvisory.png"
                            />
                        </div>
                    </div>
                    <br></br>
                    <div className="bannerImg" style={{ animation: `fadeIn .75s ease-in-out forwards 2s`, opacity: 0 }}>
                        <StaticImage
                            alt=""
                            src="../images/customAdArrows.png"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CustomAdvisory